<template>
  <!-- 会诊详情 -->
  <div class="view">
    <div class="viewtop">
      <van-notice-bar
        v-if="userInfo.type == 1"
        color="#F79C42"
        background="rgba(247,156,66,0.1)"
        left-icon="info-o"
        >待开始</van-notice-bar
      >
      <van-notice-bar
        v-if="userInfo.type == 2"
        color="#2AC2A8"
        background="rgba(42,194,168,0.1)"
        left-icon="tosend"
        >进行中</van-notice-bar
      >
      <van-notice-bar
        v-if="userInfo.type == 3"
        color="#278EFF"
        background="rgba(39,142,255,0.1)"
        left-icon="sign"
        >已完成</van-notice-bar
      >

      <div class="item top">
        <span class="userName">成大报</span>
        <span class="userInfo">男</span>
        <span class="userInfo">45岁</span>
        <span class="userInfo">12345678901</span>
      </div>
      <div class="item tbg">
        <div class="ttr">
          <span class="ttd1">会诊时间</span>
          <span class="ttd2">2021-12-21 12:30-13:00</span>
        </div>
        <div class="ttr">
          <span class="ttd1">病情概述</span>
          <span class="ttd2"
            >糖尿病及慢性并发症的防治；甲状腺结节等甲状腺疾病的诊疗；骨质酥松代谢性骨病的诊治；肥胖及代谢综合征的内、外科治疗。</span
          >
        </div>
      </div>
      <div class="item itemc">
        <div class="titles">
          <span>病历资料</span>
          <span>点击可查看大图</span>
        </div>
        <div class="imgs">
          <img
            @click="wiewImg(index, imgList)"
            v-for="(item, index) in imgList"
            :src="item"
          />
        </div>
        <div></div>
      </div>
    </div>
    <div class="viewbottom">
      <van-button
        v-if="userInfo.type == 2"
        class="btn"
        round
        block
        type="primary"
        @click="$router.push('/chatRoom')"
        >接入会诊</van-button
      >
      <van-button
        v-if="userInfo.type == 3"
        class="btn"
        round
        block
        type="primary"
        >查看对话详情</van-button
      >
    </div>
  </div>
</template>

<script>
import { NoticeBar, ImagePreview, Button } from "vant";
export default {
  name: "huiZenInfo",
  components: {
    [NoticeBar.name]: NoticeBar,
    [Button.name]: Button,
  },
  data() {
    return {
      imgList: [
        "https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg",
        "https://fastly.jsdelivr.net/npm/@vant/assets/apple-2.jpeg",
      ],
      userInfo: {
        type: 1,
      },
    };
  },
  created() {
    this.userInfo.type = this.$route.query.type;
  },
  methods: {
    wiewImg(i, list) {
      // 过滤文件，图片可左右滑动
      let arr = [];
      list.forEach((item) => {
        if (item.type && item.type.indexOf("image/") == 0) {
          arr.push(item.url);
        }
      });
      let num = 0;
      arr.forEach((ele, eidx) => {
        if (i == ele) {
          num = eidx;
        }
      });
      ImagePreview({
        images: arr,
        startPosition: num,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.view {
  background: #f4f4f7;
  height: 100vh;
  .viewtop {
    height: calc(100% - 70px);
    overflow: auto;
  }
  .viewbottom {
    height: 68px;
    .btn {
      width: 6.9rem;
      margin: auto;
    }
  }
}
.item {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
  border-radius: 8px;
  width: 7.1rem;
  margin: 10px auto 0;
  box-sizing: border-box;
  padding: 15px;
  &.top {
    // line-height: 30px;
  }
  &.tbg {
    .ttr {
      overflow: hidden;
      padding: 5px 0;
    }
    .ttd1 {
      width: 5em;
      float: left;
      color: #5d6e7f;
      font-size: 14px;
      line-height: 26px;
      vertical-align: text-top;
    }
    .ttd2 {
      font-size: 13px;
      color: #272d3b;
      float: left;
      line-height: 26px;
      width: calc(100% - 5em - 12px);
    }
  }
  &.itemc {
    .titles {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #5d6e7f;
    }
    .imgs {
      img {
        width: 1.2rem;
        height: 1.2rem;
        margin: 5px;
        object-fit: contain;
      }
    }
  }
}

.userName {
  font-weight: 500;
  color: #272d3b;
  font-size: 18px;
}
.userInfo {
  font-size: 14px;
  color: #272d3b;
  margin-left: 10px;
}
</style>
